@font-face {
    font-family: 'GisafSymbols';
    src: url('/assets/fonts/GisafSymbols.ttf') format('TrueType');
    font-weight: normal;
    font-style: normal;
}

.gisaf,.gisaf-medium,.gisaf-intext,.gisaf-short,.gisaf-tall,.gisaf-grande,.gisaf-venti  {
    font-family: 'GisafSymbols', sans-serif;
    display: inline-block;
    font-weight: normal;
    text-decoration: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.gisaf-intext { font-size: 1.5em; }

.gisaf-short { font-size: small; }
.gisaf-tall { font-size: large; }
.gisaf-grande { font-size: 32px; }
.gisaf-venti { font-size: 64px; }

.gisaf-live { font-size: 1em; }