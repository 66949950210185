/* Master Styles */

@import '@angular/material/prebuilt-themes/pink-bluegrey.css';

html, body {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  overflow: hidden;
  font-size: 80%;
  font-family: Arial, Helvetica, sans-serif;
}

.mat-mdc-raised-button, .mat-mdc-button {
  padding: 0 5px ! important;
  min-width: 68px ! important;
}

#top-toolbar {
  padding: 0;
}

nav a:hover {
  color: #efe00b;
}

nav a.active {
  color: #efe00b;
}

.notif-error {
  background-color: rgb(185, 30, 30);
  color: rgb(255, 255, 255);
}

.fill-space {
  flex: 1 1 auto;
}

[hidden] { display: none !important;}

.mat-mdc-card-header-text {
    width: 100%
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

/* XXX: Mapbox-gl canvas, should be in gisaf-mapbox/gisaf-mapbax.component.css */
.boxdraw {
  background: rgba(56,135,190,0.1);
  border: 2px solid #3887be;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}